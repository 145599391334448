import React from "react";


function Header() {
  return (
    <header>
      <h1>Awesome Notes </h1>
      
    </header>
  );
}

export default Header;
